import React from 'react'
import { Card as AlliumCard } from '@telus-uds/components-web'

import { mapDataAttrsToDataSet } from '../../../utils/dataAttributes'
import { ResponsiveProps } from '../../../utils/types/components'
import Image, { type BasicImageProps } from '../Image/Image'

export interface FileProps {
  fileName: string
  url: string
}

type FullBleedImagePositions = 'none' | 'top' | 'bottom' | 'left' | 'right'
type FullBleedContentAlignmentOptions = 'start' | 'center' | 'end'

type backgroundImageResizeOptions = 'center' | 'contain' | 'cover' | 'repeat' | 'stretch'

export type CardProps = {
  children: React.ReactNode
  background?: 'alternative' | 'subtle' | 'grid' | 'feature' | 'plain'
  padding?: 'narrow' | 'intermediate' | 'compact' | 'custom'
  borderRadius?: 'default' | 'none' | 'small' | 'large'
  fullBleedImagePosition?: ResponsiveProps<FullBleedImagePositions>
  fullBleedContentAlignment?: ResponsiveProps<FullBleedContentAlignmentOptions>
  fullBleedImage?: BasicImageProps
  decorative?: boolean
  imgAltText?: string
  limitHeight?: boolean
  useInteractiveCard?: boolean
  interactiveCardUrl?: string
  imageStyle?: string
  backgroundImageResizeOption?: ResponsiveProps<backgroundImageResizeOptions>
}

const Card = ({
  children,
  background,
  padding,
  borderRadius = 'default',
  fullBleedImagePosition = undefined,
  fullBleedContentAlignment = undefined,
  fullBleedImage,
  decorative = false,
  imgAltText = '',
  limitHeight = true,
  useInteractiveCard = false,
  interactiveCardUrl,
  imageStyle = 'full_bleed',
  backgroundImageResizeOption = undefined,
  ...rest
}: CardProps) => {
  const CardWrapper = limitHeight ? 'div' : React.Fragment
  const handleInteractiveCardPress = () => (window.location.href = interactiveCardUrl)

  return (
    <CardWrapper data-testid="card-wrapper-testid">
      <AlliumCard
        testID="card-testid"
        fullBleedContent={
          imageStyle === 'full_bleed' && fullBleedImage?.src && !useInteractiveCard
            ? {
                position: fullBleedImagePosition,
                align: fullBleedContentAlignment,
                content: (
                  <Image
                    src={fullBleedImage.src}
                    width={fullBleedImage.width}
                    height={fullBleedImage.height}
                    // if imgAltText empty use the text from the image description
                    alt={imgAltText || fullBleedImage.alt}
                    decorative={decorative}
                    // TODO: implement sizes
                    // sizes={{ sm: 100, md: 50, xl: 35 }}
                  />
                ),
              }
            : undefined
        }
        backgroundImage={
          imageStyle === 'background' && fullBleedImage?.src && !useInteractiveCard
            ? {
                alt: imgAltText || fullBleedImage.alt,
                resizeMode: backgroundImageResizeOption,
                src: fullBleedImage.src,
              }
            : undefined
        }
        variant={{
          background,
          padding,
          borderRadius,
        }}
        dataSet={mapDataAttrsToDataSet(rest)}
        interactiveCard={useInteractiveCard ? { body: children } : undefined}
        onPress={useInteractiveCard && interactiveCardUrl ? handleInteractiveCardPress : undefined}
      >
        {!useInteractiveCard && children}
      </AlliumCard>
    </CardWrapper>
  )
}

export default Card
